import * as React from "react";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";

// import hero from '../images/header.jpg';

import "../styles/index.css";
import Footer from "../components/footer";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Supervision</title>
        <meta name="description" content="Supervision" />
        <meta
          name="keywords"
          content="supervision, freiburg, schweiz, deutschland, beratung, frauen, online"
        />
      </Helmet>

      <Hero
        title="Heinz von Foerster"
        heading="„Handle stets so, dass die Anzahl der Möglichkeiten wächst‟"
        image="2022/moeven.jpg"
      />

      <div className="flex items-center justify-center">
        <div className="w-4/5 md:w-2/3 md:mt-20 z-10 leading-8 bg-white bg-opacity-70 rounded-lg p-10">
          In der Supervision betrachten und reflektieren wir gemeinsam
          professionelles Handeln und institutionelle Strukturen. Durch
          systemische Methoden wird das Team ermutigt Herausforderungen zu
          reflektieren und eigene Konflikt und Problemlösungen zu erarbeiten.
          <br />
          Die Wechselwirkung zwischen Person, Rolle, Funktion, Auftrag und
          Organisation wird dabei kontextbezogen integriert.
          <br />
          Meine Rolle als systemische Supervisorin nehme ich auftragsbezogen und
          prozessorientiert in Team-, Fall- oder Gruppensupervisionen wahr.
        </div>
      </div>

      <Buchung />

      <Footer />
      <CookieConsent />
    </Theme>
  );
};

export default IndexPage;
